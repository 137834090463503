import { useEffect, useMemo } from 'react';
import { Modal } from 'antd';

import './style.scss';
import IconCommon from 'components/IconCommon';
import moment from 'moment';

const EmulatorMobile = ({ onClose, item, isOpen }) => {
    const expiredOffer = useMemo(() => moment(item?.expiredDate).isBefore() < 0, []);
    if (!isOpen)
        return null;

    return (
        <>
            <Modal
                visible={isOpen}
                title=''
                onOk={onClose}
                footer={null}
                onCancel={onClose}
            >
                <div className="flex justify-center">
                    <div className='emulator-mobile'>
                        <div className='header bg-white'>
                            <div className='background-banner'
                                 style={{ backgroundImage: `url(${item.bannerUrl})` }}
                            >
                                <div className='header-mobile flex flex-col justify-between'>
                                    <div className='flex justify-between'>
                                        <IconCommon type='icon-back' />
                                        <IconCommon type='icon-heart' />
                                    </div>
                                    <div className='avatar-brand'
                                         style={{ backgroundImage: `url(${item.logoUrl})` }}>
                                    </div>
                                </div>
                            </div>

                            <div style={{ padding: '1rem 1rem 0' }}>
                                <div className='title-offer'>
                                    {item.title}
                                </div>
                            </div>

                            <div className='title-expired'>
                                {expiredOffer ?
                                    'Đã hết hạn' :
                                    <span>
                                    Hiệu lực đến hết ngày &nbsp;
                                        <b>{moment(item.expiredDate).format('DD/MM/YYYY')}</b>
                                </span>
                                }
                            </div>
                        </div>

                        <div className='mt-2 offer-content bg-white'>
                            <div className='custom-title'>
                                Chi tiết ưu đãi
                            </div>

                            <p className='mt-2 default-ul-ol'
                               dangerouslySetInnerHTML={{ __html: item.description.replaceAll('\n', `<br/>`) }}>
                            </p>
                        </div>

                        {item.useMethod &&
                            <div className='mt-3 offer-content bg-white'>
                                <div className='custom-title'>
                                    Cách áp dụng
                                </div>

                                <p className='mt-3 default-ul-ol'
                                   dangerouslySetInnerHTML={{ __html: item.useMethod.replaceAll('\n', `<br/>`) }}>
                                </p>
                            </div>
                        }

                        <div className='offer-content bg-white mt-2'>
                            <div className='custom-title'>
                                Điều kiện áp dụng
                            </div>

                            <div className='w-full mt-3 default-ul-ol'>
                                {item?.conditions?.map((condition) => (
                                    <div key={condition}
                                         dangerouslySetInnerHTML={{ __html: condition }}></div>
                                ))}
                            </div>
                        </div>


                        {(item.linkWeb || item.phoneNumber || item.address || item.addressLink) &&
                            <div className='offer-footer  bg-white mt-2'>
                                {
                                    item.address &&
                                    <>
                                        <div className="border-b">
                                            <div className='custom-title'>Địa chỉ</div>
                                            <div className="py-4 ">{item.address}</div>
                                        </div>
                                        <br/>
                                    </>
                                }
                                <div style={{ marginTop: `${item.address ? '-6px' : '-3px'}`}}
                                     className="flex mr-3">
                                    {item.linkWeb &&
                                        <>
                                             <span className='mr-12 flex'>
                                                <IconCommon type='icon-copy' className='mr-3' />
                                                Web
                                            </span>
                                            &nbsp; &nbsp;
                                        </>
                                    }
                                    {
                                        item.addressLink &&
                                        <>
                                            <span className="flex items-center mr-5 custom-icon">
                                              <IconCommon type="icon-store-solid" className="mr-3 h-4 w-4" />
                                              Cửa hàng
                                            </span>
                                            &nbsp; &nbsp;
                                        </>
                                    }
                                    {item.phoneNumber && (
                                        <>
                                        <span className='flex' >
                                            <IconCommon type='icon-phone' className='mr-1' />
                                            &nbsp; {item.phoneNumber}
                                        </span>
                                        </>
                                    )}

                                </div>
                            </div>
                        }
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default EmulatorMobile;